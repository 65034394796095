<template>
  <c-box
    padding="16px"
    border-radius="8px"
    background-color="neutral.white"
    box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
    :cursor="isDataMenuSkipped ? 'not-allowed' : 'pointer'"
    :_hover="{
      backgroundColor: 'neutral.superLightGray',
    }"
    class="client_meal-plan_menu-recommendation_4"
    :class="isTourActive ? 'pointer-events-none' : ''"
    @click="onOpenMenuRecommendation"
  >
    <c-flex
      align-items="center"
      justify-content="space-between"
      margin-bottom="8px"
      :opacity="isDataMenuSkipped ? '0.5' : '1'"
    >
      <c-text
        :font-size="['14px','18px']"
        :font-weight="['500','600']"
        color="neutral.black"
      >
        {{ item?.label }}
        <c-text
          v-if="!isDataMenuSkipped && isDataMenuExist"
          as="span"
        >
          - {{ item?.mealPlanSchedule?.caloriesAmount ?? 0 }} kkal
        </c-text>
      </c-text>
      <c-flex
        v-if="item?.data"
        align-items="center"
        gap="12px"
      >
        <c-text
          color="neutral.superDarkGray"
          :font-size="['12px','16px']"
          :font-weight="['400','500']"
        >
          {{ format24Time(item?.mealPlanSchedule?.startTime) }} - {{ format24Time(item?.mealPlanSchedule?.endTime) }}
        </c-text>
        <c-box
          :width="['12px', '14px']"
          :height="['12px', '14px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(-90deg);"
          />
        </c-box>
      </c-flex>
    </c-flex>
    <ListMenuRecommendation
      v-if="isDataMenuExist"
      :items="item?.data?.dataMenu"
    />
    <c-flex
      v-else-if="isDataMenuSkipped"
      align-items="center"
      gap="8px"
      opacity="0.5"
    >
      <c-box
        :min-height="['14px', '18px']"
        :min-width="['14px', '18px']"
        :height="['14px', '18px']"
        :width="['14px', '18px']"
        color="#1A202C"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-info-2.svg')"
          height="100%"
          width="100%"
        />
      </c-box>
      <c-text
        :font-size="['12px','16px']"
      >
        Jam makan di skip
      </c-text>
    </c-flex>
    <c-flex
      v-else
      align-items="center"
      gap="8px"
    >
      <c-box
        :min-height="['14px', '18px']"
        :min-width="['14px', '18px']"
        :height="['14px', '18px']"
        :width="['14px', '18px']"
        color="#DA6D06"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-info-2.svg')"
          height="100%"
          width="100%"
        />
      </c-box>
      <c-text
        :font-size="['12px','16px']"
        color="warning.400"
      >
        Jam makan belum tersedia
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
import ListMenuRecommendation
  from '@/components/meal-plan/_widgets/list-menu-recommendation.vue'
import { CBox, CFlex } from '@chakra-ui/vue'
import { format24Time } from '@/utils/format-24-time'

export default {
  name: 'CardInfoMenuRecommendation',
  components: {
    CBox,
    CFlex,
    ListMenuRecommendation,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isTourActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open-menu-recommendation'],
  computed: {
    isDataMenuExist() {
      return this.item?.data?.dataMenu && this.item?.data?.dataMenu?.length > 0
    },
    isDataMenuSkipped() {
      return this.item?.mealPlanSchedule?.isSkipped ? true : false
    },
  },
  methods: {
    format24Time,
    onOpenMenuRecommendation() {
      if (!this.isDataMenuSkipped) {
        this.$emit('open-menu-recommendation', this.item)
      }
    },
  },
}
</script>
